@import url(https://fonts.googleapis.com/css?family=Inter:regular,semi-bold,medium,bold,italic&subset=latin,latin-ext&display=swap);
html {
  width: 100%;
  height: 100%;
  background-color: #111220;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

body {
  margin: 0;
  font-family: 'inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
}

h2 {
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
}

.horizontalStack {
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  display: flex;
  position: relative;
}

.verticalStack {
  /* Auto Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
